export function makeId() {
    var id = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    for (var i = 0; i < 5; i++) {
      id += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return id;
}

export function makeSupplierId() {
  var id = Math.floor(Math.random() * 9999999) + 1;
  id = id.toString();
  var number_of_padding_zeros = 7 - id.length
  for (var i = 0 ; i < number_of_padding_zeros; i++){
      id = "0" + id
  }
  return id;
}

export function getTrackingLink(shipping_method, tracking_number) {
  if (shipping_method == "DHL") {
    return "http://www.dhl.com/en/express/tracking.html?AWB=" + tracking_number + "&brand=DHL";
  } else if (shipping_method == "FED") {
    return "https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=" + tracking_number + "&action=track";
  } else if (shipping_method == "EMS") {
    return "https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S004&locale=en&reqCodeNo1=" + tracking_number;
  } else if (shipping_method == "ePacket") {
    return "https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S004&locale=en&reqCodeNo1=" + tracking_number;
  } else if (shipping_method == "UPS") {
    return "https://wwwapps.ups.com/etracking/tracking.cgi?InquiryNumber1=" + tracking_number + "&TypeOfInquiryNumber=T&AcceptUPSLicenseAgreement=yes&submit=Track";
  } else if (shipping_method == "POSTAL PARCEL") {
    return "https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S004&locale=en&reqCodeNo1=" + tracking_number;
  } else if (shipping_method == "Small Packet") {
    return "https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S004&locale=en&reqCodeNo1=" + tracking_number;
  }
  return "";
}

export function getBackendLink(shop) {
  switch(shop) {
    case 'KSEN':
      return 'https://kusakurashop.myshopify.com/admin/'
      break
    case 'SSTE':
      return 'https://seidostore.myshopify.com/admin/'
      break
    case 'SSEN':
      return 'https://seidoshop.myshopify.com/admin/'
      break
    case 'SSFR':
      return 'https://seidoshopfr.myshopify.com/admin/'
      break
    case 'SSJP':
      return 'https://seido-shop.myshopify.com/admin/'
      break
  }
}

// id: can be either a GraphQl id or a rest ID
export function getBackendProductLink(shop, id) {
  if (String(id).includes('gid')) {
    let tmp = id.split('/')
    id = tmp[tmp.length-1]
  }
  return `${getBackendLink(shop)}products/${id}`
}

// gid: need to be a GraphQl id
export function getBackendResourceLink(shop, gid) {
    let tmp = gid.split('/')
    let id = tmp[tmp.length-1]
    let resourceType = tmp[tmp.length-2]
    switch (resourceType) {
      case 'Product':
        return getBackendProductLink(shop, id)
        break
      case 'Collection':
        return `${getBackendLink(shop)}collections/${id}`
        break
      case 'OnlineStoreArticle':
        return `${getBackendLink(shop)}articles/${id}`
        break
      case 'OnlineStorePage':
        return `${getBackendLink(shop)}pages/${id}`
        break
      case 'EmailTemplate':
        return `${getBackendLink(shop)}settings/notifications/`
        break
      default:
        return `${getBackendLink(shop)}`
        break
    }
}

export function encodeQueryData(data) {
  var ret = [];
  for (var d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

export function displayResponseMessages(messages){
  var event = new CustomEvent(
    "response_messages",
    {
        detail: {
          messages: messages
        }
    }
  );
  document.getElementById("event-element").dispatchEvent(event);
}

export function setLoading(state){
  var event = new CustomEvent(
    "loading_state",
    {
        detail: {
          state: state
        }
    }
  );
  document.getElementById("event-element").dispatchEvent(event);
}

export function getCsrfToken(){
  return document.getElementsByName('csrf-token').item(0).content;
}

export function addLoadingListener(component){
  document.getElementById("event-element").addEventListener("loading_state", (e) => {
    component.setState({ loading: e.detail.state });
  }, false);
}


export function removeLoadingListener(component){
  document.getElementById("event-element").removeEventListener("loading_state", (e) => {
    component.setState({ loading: e.detail.state });
  }, false);
}