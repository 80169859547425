import React from "react"
import PropTypes from "prop-types"
import { setLoading, displayResponseMessages } from "../utils/utils.js"

class Navbar extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    if (this.props.type != "SIGN_IN") {
      return (
        <header>
          <nav className="navbar navbar-expand-lg navbar-dark bg-seido h-100" id="navbar-seido">
            <div className="container-fluid d-flex flex-column flex-md-row justify-content-between">
              <a className="navbar-brand" href="/">
                <img className="mr-2" src="https://cdn.shopify.com/s/files/1/1129/2992/files/seido-logo-simple-grey.png?14292095371928864183" />
                <span className="bg-seido-font-gray seido-title">MTA </span>
                <span className="bg-seido-font-gray seido-title seido-subtitle">{this.props.title}</span>
              </a>
              <div className="ml-auto">
                <form className="p-0 form-inline">
                  <img className="profile-picture" title={this.props.currentUser.name} src={this.props.currentUser.profile_picture} />
                  <a className="navbar-btn btn btn-seido-secondary" href="/signout" role="button">Sign Out</a>
                </form>
              </div>
            </div>
          </nav>
        </header>
      )
    } else {
      return (
        <header>
          <nav className="navbar navbar-expand-lg navbar-dark bg-seido h-100" id="navbar-seido">
            <div className="container-fluid d-flex flex-column flex-md-row justify-content-between">
              <a className="navbar-brand" href="/"><img className="mr-2" src="https://cdn.shopify.com/s/files/1/1129/2992/files/seido-logo-simple-grey.png?14292095371928864183" /><span className="bg-seido-font-gray seido-title">MTA</span></a>
              <div className="ml-auto" >
                <form className="p-0form-inline">
                  <a className="navbar-btn btn btn-seido-primary" href="/auth/google_oauth2" role="button">Sign In</a>
                </form>
              </div>
            </div>
          </nav>
        </header>
      )
    }
  }
}

export default Navbar
