import React from "react"
import PropTypes from "prop-types"
import Navbar from "./Navbar.jsx"

class Signin extends React.Component {
  // warning: there are too component Signin.jsx

  render () {
    let notification = this.props.flash ? <div  style={{width: '100%', paddingTop: '20px', textAlign: 'center'}}><div className='alert alert-danger' >{this.props.flash}</div></div> : <></>

    return (
      <>
        <Navbar type="SIGN_IN" />
        {notification}
      </>
    );
  }
}

export default Signin
